import React, { useState, useEffect } from 'react'
import { Typography, Box, Stack, Button } from '@mui/material'
import Layout from '../../components/Layout'
import PageBackButton from '../../components/PageBackButton'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../style/theme'
import { useParams } from 'react-router-dom'
import SettingsIcon from '@mui/icons-material/Settings'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import type { PatchFlipSettingResponse }  from '../../api/setting/responseType'
import { useGetSortSetting, usePatchSortSetting } from 'api/setting'
import OperationProof from 'components/OperationProof'
import { ErrorModal, ErrorModalOnCloseProps, ErrorModalProps } from 'components/ErrorModal'
import { CompleteModal, CompleteModalOnCloseProps, CompleteModalProps } from 'components/CompleteModal'
import { AxiosError } from 'axios'
import useResetQuery from 'api/useResetQuery'

const SettingSort = () => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const [ patientList, setPatientList ] = useState<number>(0)
  const [ examinationList, setExaminationList ] = useState<number>(0)
  const [ comparisonPhotos, setComparisonPhotos ] = useState<number>(0)
  const { data: sortData, isError: isSortError, isPending: isSortPending } = useGetSortSetting()
  const { mutate: postMutate } = usePatchSortSetting()
  const [completeModalConfig, setCompleteModalConfig] = useState<CompleteModalProps | undefined>()
  const [operationProofing, setOperationProofing] = useState(false)
  const [errorModalConfig, setErrorModalConfig] = useState<ErrorModalProps | undefined>()
  const resetQuery = useResetQuery()

  useEffect(() => {
    if (isSortError) {
      console.error(isSortError)
      return
    }

    if (!isSortPending && sortData !== undefined) {
      const setting = sortData?.sort
      setPatientList(setting.patientList)
      setExaminationList(setting.examinationList)
      setComparisonPhotos(setting.comparisonPhotos)
    }  
  }, [isSortError, sortData, isSortPending])

  if (isSortPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  const handleSubmit = async () => {
    setOperationProofing(true)
    postMutate(
      {
        req: {
          patientList,
          examinationList,
          comparisonPhotos
        }
      },
      {
        onSuccess: async (response: PatchFlipSettingResponse) => {
          setOperationProofing(false)
          if (response.status === 'OK') {
            await new Promise<CompleteModalOnCloseProps>((resolve) => {
              setCompleteModalConfig({
                onClose: resolve,
                title: `登録が完了しました。`
              })
            })
            setCompleteModalConfig(undefined)

            resetQuery(['sortSetting'])

          } else {
            await new Promise<ErrorModalOnCloseProps>((resolve) => {
              setErrorModalConfig({
                onClose: resolve,
                title: '登録に失敗しました。'
              })
            })
            setErrorModalConfig(undefined)
          }
        },
        onError: async (error: AxiosError) => {
          setOperationProofing(false)
          await new Promise<ErrorModalOnCloseProps>((resolve) => {
            setErrorModalConfig({
              onClose: resolve,
              title: '登録に失敗しました。'
            })
          })
          setErrorModalConfig(undefined)

        }
      }
    )
  }

  const handlePatientListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientList(parseInt(event.currentTarget.value))
  }

  const handleExaminationListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExaminationList(parseInt(event.currentTarget.value))
  }

  const handleComparisonPhotosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComparisonPhotos(parseInt(event.currentTarget.value))
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={`/${clinicId}/setting`} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 4, mt: 1, mb: 4}}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <SettingsIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>表示順の設定</Typography>
            </Box>
            <Typography sx={{ mt: 2 }}>各種一覧画面での表示順を設定します</Typography>
            <Stack>
              <FormControl sx={{ mt: 4 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">患者一覧（患者並び順）</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  onChange={handlePatientListChange}
                  value={patientList}
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value={0} control={<Radio />} label="新しい順" />
                  <FormControlLabel value={1} control={<Radio />} label="登録順" />
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ mt: 4 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">患者詳細（撮影日並び順）</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  onChange={handleExaminationListChange}
                  value={examinationList}
                  name="row-radio-buttons-group"
                >
                <FormControlLabel value={0} control={<Radio />} label="新しい順" />
                <FormControlLabel value={1} control={<Radio />} label="古い順" />
                </RadioGroup>
              </FormControl> 
              <FormControl sx={{ mt: 4 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">比較写真（撮影日並び順）</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  onChange={handleComparisonPhotosChange}
                  value={comparisonPhotos}
                  name="row-radio-buttons-group"
                >
                <FormControlLabel value={0} control={<Radio />} label="新しい順" />
                <FormControlLabel value={1} control={<Radio />} label="古い順" />
                </RadioGroup>
              </FormControl>  
            </Stack>
            <Button
              variant="outlined"
              onClick={handleSubmit}
              sx={{ mt: 4, py: 0.5 , px: 2 ,background: theme.palette.primary.main , color: "white", borderRadius: "60px", fontWeight: "bold", width: "160px", fontSize: "14px", "&:hover": { backgroundColor: "white", color: theme.palette.primary.main } }}
            >
              適用する
            </Button>  
          </Box>
          {completeModalConfig && <CompleteModal {...completeModalConfig} />}
          {errorModalConfig && <ErrorModal {...errorModalConfig} />}
          {operationProofing && <OperationProof initial={false} />}
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default SettingSort