import { client } from 'lib/AxiosClientProvider'
import { PhotoSave, PhotoUpdate, ReassignPhoto, SwapPhoto } from './requestType'
import { DeleteExaminationResponse, GetAllPhotoByExamResponse, GetAllPhotoResponse, GetEditedPhotoFilenameResponse, GetExaminationListResponse, GetPhotoListByExamResponse, GetPhotoListByTimelapseResponse, GetUnassignedPhotoResponse, PatchPhotoResponse, PostPhotoResponse, ReassignPhotoResponse, SwapPhotoResponse } from './responseType'

export const getExnamiationList = async (patientId: number, order?: string): Promise<GetExaminationListResponse> => {
  const response = await client.get(`/patient/${patientId}/photo`, {
    params: {order}
  })

  return response.data
}

export const getPhotoListByExam = async (patientId: number, examinationId: number): Promise<GetPhotoListByExamResponse> => {
  const response = await client.get(`/patient/${patientId}/photo/exam/${examinationId}`)

  return response.data
}

export const getPhotoListByTimelapse = async (patientId: number, angle: number): Promise<GetPhotoListByTimelapseResponse> => {
  const response = await client.get(`/patient/${patientId}/photo/angle/${angle}`)

  return response.data
}

export const getAllPhoto = async (patientId: number): Promise<GetAllPhotoResponse> => {
  const response = await client.get(`/patient/${patientId}/photo/all`)

  return response.data
}

export const getAllPhotoByExam = async (patientId: number, examinationId: number): Promise<GetAllPhotoByExamResponse> => {
  const response = await client.get(`/patient/${patientId}/photo/all/${examinationId}`)

  return response.data
}

export const getUnassignedPhoto = async (): Promise<GetUnassignedPhotoResponse> => {
  const response = await client.get(`/unassigned`)

  return response.data
}

export const getEditedPhoto = async (patientId: number, tableName: 'photos'|'vagabonds', photoId: number): Promise<GetEditedPhotoFilenameResponse> => {
  const response = await client.get(
    `/patient/${patientId}/photo/download/${tableName}/${photoId}`,
    {
      timeout: 20000
    }
  )

  return response.data
}

export const postPhoto = async ({
  patientId,
  req
}: {
  patientId: number,
  req: PhotoSave,
}): Promise<PostPhotoResponse> => {
  const response = await client.post(`/patient/${patientId}/photo/save`, req)

  return response.data
}

export const patchPhoto = async ({
  patientId,
  examinationId,
  req
}: {
  patientId: number,
  examinationId: number,
  req: PhotoUpdate,
}): Promise<PatchPhotoResponse> => {
  const response = await client.patch(`/patient/${patientId}/photo/${examinationId}/save`, req)

  return response.data
}

export const reassignPhoto = async ({
  patientId,
  examinationId,
  req
}: {
  patientId: number,
  examinationId: number,
  req: ReassignPhoto,
}): Promise<ReassignPhotoResponse> => {
  const response = await client.patch(`/patient/${patientId}/photo/${examinationId}/reassign`, req)

  return response.data
}

export const swapPhoto = async ({
  patientId,
  examinationId,
  req
}: {
  patientId: number,
  examinationId: number,
  req: SwapPhoto,
}): Promise<SwapPhotoResponse> => {
  const response = await client.patch(`/patient/${patientId}/photo/${examinationId}/swap`, req)

  return response.data
}

export const deleteExamination = async ({
  patientId,
  examinationId,
}: {
  patientId: number,
  examinationId: number,
}): Promise<DeleteExaminationResponse> => {
  const response = await client.delete(`/patient/${patientId}/photo/${examinationId}`)

  return response.data
}
