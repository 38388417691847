import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Layout from 'components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import PageBackButton from 'components/PageBackButton'
import { Alert, Box, Button, Checkbox, CircularProgress, FormControlLabel, ListItem, MenuItem, Select, SelectChangeEvent, Snackbar, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useGetPatient } from 'api/patient'
import { useGetExaminationList } from 'api/photo'
import { getImageUrl } from 'lib/getImagePath'
import { Patient } from 'api/patient/responseType'
import { Examination, Photo } from 'api/photo/responseType'
import dayjs from 'dayjs'
import Scrollbars from 'react-custom-scrollbars-2'
import PhotoFrame from 'components/PhotoFrame'
import { calculateThatTimeAge } from 'lib/patientService'
import ReactToPrint from 'react-to-print'
import AvatarRender from 'components/AvatarRender'
import { defaultAffine, getAlterPath } from 'types/PhotoObject'
import useResetQuery from 'api/useResetQuery'
import { useGetSortSetting } from 'api/setting'

type PrintComparisonProps = {
  id: string
  clinicId: string
}

const PrintComparison = () => {
  const loc = useLocation()
  const urlParams = useParams<PrintComparisonProps>()
  const id = urlParams.id ? parseInt(urlParams.id) : 0
  const clinicId = urlParams.clinicId ?? ''
  const [searchParams, setSearchParams] = useSearchParams()
  const [patient, setPatient] = useState<Patient | null>(null)
  const [examinations, setExaminations] = useState<Examination[]>([])
  const [sortSetting, setSortSetting] = useState<number>(0)
  const { data: sortData, isError: isSortError, isPending: isSortPending } = useGetSortSetting()
  const orderFromParams = searchParams.get('order') || sortSetting ? 'asc' : 'desc'
  const [order, setOrder] = useState(orderFromParams)
  const {
    data: patientData, isError: isPatientError, isPending: isPatientPending,
    isRefetching: isPatientRefetchPending, isRefetchError: isPatientRefetchError
  } = useGetPatient(id)
  const {
    data: photoData, isError: isExaminationError, isPending: isExaminationPending,
    isRefetching: isExaminationRefetchPending, isRefetchError: isExaminationRefetchError
  } = useGetExaminationList(id, order)
  const [printList, setPrintList] = useState<number[]>([])
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarFlag, setSnackBarFlag] = useState('')
  const printRef = useRef(null)
  const targetRef = useRef<(HTMLDivElement | null)[]>([])
  const resetQuery = useResetQuery()

  const previosPagePath = useMemo(() => {
    return loc.state ?? `/${clinicId}/patient/${id}`
  }, [clinicId, id, loc.state])

  const getImagePath = useCallback(() => {
    const path = patientData?.patient.face

    if (path === undefined || !path) {
      return ''
    }

    return getImageUrl(path)
  }, [patientData])

  const getFaceAffine = useCallback(() => {
    const affine = patientData?.patient.affine

    if (affine === undefined || !affine) {
      return defaultAffine
    }

    return affine
  }, [patientData])

  const imagePath = getImagePath()
  const affine = getFaceAffine()

  const calculateAge = useCallback((date: string | undefined) => {
    if (!date || date === undefined ) {
      return ''
    }

    const converted = dayjs(date)
    const ageYear = dayjs().diff(converted, 'year')
    const ageTotalMonth = dayjs().diff(converted, 'month')
    const ageMonth = !isNaN(ageTotalMonth) ? ageTotalMonth % 12 : 0

    return !isNaN(ageYear) ? `${ageYear}歳 ${ageMonth}ヶ月` : ''
  }, [])
    
  const memoizedResetQuery = useRef(() => {
    resetQuery(['photoList', id])
  })

  useEffect(() => {
    if ((searchParams.get('order') || (sortSetting ? 'asc' : 'desc')) !== order) {
      setOrder(searchParams.get('order') || (sortSetting ? 'asc' : 'desc'))
      setPrintList([])
      memoizedResetQuery.current()
    }

    if (
      isPatientError || isPatientRefetchError
      || isExaminationError || isExaminationRefetchError
      || isSortError
    ) {
      console.error(isPatientError)
      console.error(isExaminationError)
      console.error(isSortError)

      return
    }
    if(!isPatientPending && !isPatientRefetchPending && patientData !== undefined){
      setPatient(patientData.patient)
    }
    if(!isExaminationPending && !isExaminationRefetchPending && photoData !== undefined){
      setExaminations(photoData.examinations)
    }
    if(!isSortPending && sortData !== undefined){
      setSortSetting(sortData.sort.comparisonPhotos)
    }
  }, [
    patientData, isPatientError, isPatientPending, isPatientRefetchPending, isPatientRefetchError,
    photoData, isExaminationError, isExaminationPending, isExaminationRefetchPending, isExaminationRefetchError,
    sortData, isSortError, isSortPending,
    searchParams,
    sortSetting,
    order,
    memoizedResetQuery
  ])

  const imageGetter = (examination: Examination,angle: number) => {
    return examination.photo?.find((photo:Photo) => photo.angle === angle)
  }

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const newList: number[] = structuredClone(printList)
    if (target.checked) {
      if (printList.length >= 4) {
        setSnackBarOpen(true)
        setSnackBarMessage('4組まで選択可能です。')
        setSnackBarFlag('error')
        target.click()
        return
      }
      newList.push(parseInt(target.value))
      targetRef.current[parseInt(target.value)]?.classList.add('showPrint')
      setPrintList(newList)
    } else {
      targetRef.current[parseInt(target.value)]?.classList.remove('showPrint')
      setPrintList(newList.filter(data => data !== parseInt(target.value)))
    }
  }

  const beforePrint = async () => {
    if (printList.length < 2) {
      setSnackBarOpen(true)
      setSnackBarMessage('最低でも2組選択してください。')
      setSnackBarFlag('error')
      return false
    }
    if (printList.length > 4) {
      setSnackBarOpen(true)
      setSnackBarMessage('4組まで選択可能です。')
      setSnackBarFlag('error')
      return false
    }
    return true
  }

  const handleSnackClose = () => {
    setSnackBarOpen(false)
  }
    
  const handleChangeOrder = (event: SelectChangeEvent<string>) => {
    const value = event.target.value

    // 現在の検索パラメータを取得
    const params = new URLSearchParams()

    // 表示順が新しい順の場合はパラメータを削除。古い順の場合は更新
    if (value !== (sortSetting ? 'asc' : 'desc')) {
      params.set('order', value)
    }

    // URLパラメータを更新（refetch実行）
    setSearchParams(params)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={previosPagePath} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 4 }} ref={printRef}>
            <Stack sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} direction = "row">
                <ListItem alignItems="center" sx={{ display: "flex", columnGap: "24px", p: 0 }}>
                  <AvatarRender imagePath={imagePath} affine={affine} frameWidth={64} frameHeight={64} />
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: theme.palette.text.primary }}>
                      {patient?.kana}
                    </Typography>
                    <Typography sx={{ fontSize: "24px", fontWeight: "bold", color: theme.palette.text.primary }}>
                      {patient?.name}
                    </Typography>
                  </Box>
                </ListItem>
                <Stack sx={{ display: "flex", columnGap: "16px" }} direction = "row" className="hidePrint">
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        variant="outlined" disabled={printList.length < 2 || printList.length > 4}
                        sx={{
                          py: 0.5, px: 2, width: "160px", 
                          background: theme.palette.primary.main, color: "white",
                          borderRadius: "60px", fontWeight: "bold", fontSize: "14px",
                          "&:hover": { backgroundColor: "white", color: theme.palette.primary.main },
                          "&:disabled": { backgroundColor: "white", color: theme.palette.secondary.dark },
                        }}
                      >
                        PDFで出力
                      </Button> 
                    )}
                    onBeforePrint={beforePrint}
                    content={() => printRef.current}
                  />                                                              
                </Stack>
                <Stack maxWidth={4/9} sx={{ p: 0, display: "flex", justifyContent: "space-between", alignItems: "flex-start" }} direction="row" className="printArea showPrint">
                  <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)", tableLayout: "fixed" }}>
                    <TableBody sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)", borderLeft: "1px solid rgba(224, 224, 224, 1)"}} >
                        <TableRow>
                          <TableCell width={72} sx={{ background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                            患者番号
                          </TableCell>
                          <TableCell align="left" width={80} sx={{borderRight: "1px solid rgba(224, 224, 224, 1)"}} >{patient?.patientNo ?? '-'}</TableCell>                          
                          <TableCell width={72} sx={{ background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                            年齢
                          </TableCell>
                          <TableCell align="left" width={80}>{calculateAge(patient?.birthday)}</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </Stack>
            </Stack>
            <Stack sx={{ p: 0, mt: 2 ,display: "flex", justifyContent: "space-between", alignItems: "flex-start" }} direction="row" className="hidePrint">
              <Table sx={{ border: "1px solid rgba(224, 224, 224, 1)", width:"44%" }}>
                <TableBody sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)", borderRight: "1px solid rgba(224, 224, 224, 1)", borderLeft: "1px solid rgba(224, 224, 224, 1)"}} >
                    <TableRow>
                      <TableCell sx={{ width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                        患者番号
                      </TableCell>
                      <TableCell align="left">{patient?.patientNo ?? '-'}</TableCell>
                    </TableRow>                  
                    <TableRow>
                      <TableCell sx={{ width: "72px", background: "#F4F4F4", fontWeight: "bold", borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                        年齢
                      </TableCell>
                      <TableCell align="left">{calculateAge(patient?.birthday)}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </Stack>
            <Stack direction="row" justifyContent="space-between" className="hidePrint" mt={2}>
              <Typography color="gray" fontSize={12}>※2組から4組まで選択可能です。</Typography>
              <Select
                  displayEmpty
                  sx={{ width: 120, background: 'white', borderRadius: '8px' }}
                  value={order}
                  onChange={handleChangeOrder}
                >
                <MenuItem value='desc'>
                  新しい順
                </MenuItem>
                <MenuItem value='asc'>
                  古い順
                </MenuItem>
              </Select>
            </Stack>
            {
              isExaminationPending || isExaminationRefetchPending ?
              <Stack justifyContent="center" alignItems="center" width={1 / 1} mt={1}>
                <CircularProgress />
              </Stack>
              :
              <Box sx={{ mt: 2, width: '100%'}}>
                {examinations.map((examination) => {
                  const photoExists = examination.photo?.filter(
                    (photo:Photo) => [10, 11, 12, 13, 14].includes(photo.angle)
                  ).length !== 0
                  return photoExists && patient && (
                    <Stack
                      sx={{ border: '2px solid #F4F4F4', alignItems: "center", mb: 2 }}
                      direction="row" className="printSwitch"
                      key={examination.id} ref={el => (targetRef.current[examination.id] = el)}
                    >
                      <Stack sx={{ width: '192px', display: "flex", alignItems: "center", backgroundColor: "#F4F4F4", height: "134px", justifyContent: "center" }} direction = "column">
                        <Typography fontWeight="bold" className="printArea">
                          {examination.examinationDate}
                        </Typography>
                        <Typography fontSize={12} className="printArea">
                          {calculateThatTimeAge(patient.birthday, examination.examinationDate)}
                        </Typography>
                        <FormControlLabel 
                          control={<Checkbox onChange={(e) => handleCheck(e)}value={examination.id}/>}
                          label={
                            <Stack sx={{ width: '192px', display: "flex", alignItems: "center", backgroundColor: "#F4F4F4", justifyContent: "center" }} direction = "column">
                              <Typography fontWeight="bold">
                                {examination.examinationDate}
                              </Typography>
                              <Typography fontSize={12}>
                                {calculateThatTimeAge(patient.birthday, examination.examinationDate)}
                              </Typography>
                            </Stack>
                          }
                          labelPlacement="top" className="hidePrint"
                        />
                      </Stack>
                      <Stack sx={{ width: 'calc(100% - 120px)', scrollbarWidth: "none", msOverflowStyle :"none" }} overflow="scroll" whiteSpace="nowrap">
                        <Scrollbars style={{ width: '100%', height: '134px'}}>
                          <Stack alignItems="center" justifyContent="flex-start" gap={0} direction="row" width="calc(168px * 5)">
                            <Button
                              disabled={true}
                            >
                              <Box>
                                <PhotoFrame
                                  image={imageGetter(examination, 13)}
                                  alterPath={getAlterPath(13)}
                                />
                              </Box>
                            </Button>
                            <Button
                              disabled={true}
                            >
                              <Box>
                                <PhotoFrame
                                  image={imageGetter(examination, 10)}
                                  alterPath={getAlterPath(10)}
                                />
                              </Box>
                            </Button>
                            <Button
                              disabled={true}
                            >
                              <Box>
                                  <PhotoFrame
                                    image={imageGetter(examination, 14)}
                                    alterPath={getAlterPath(14)}
                                  />
                              </Box>
                            </Button>
                            <Button
                              disabled={true}
                            >
                              <Box>
                                <PhotoFrame
                                  image={imageGetter(examination, 11)}
                                  alterPath={getAlterPath(11)}
                                />
                              </Box>
                            </Button>
                            <Button
                              disabled={true}
                            >
                              <Box>
                                <PhotoFrame
                                  image={imageGetter(examination, 12)}
                                  alterPath={getAlterPath(12)}
                                />
                              </Box>
                            </Button>
                          </Stack>
                        </Scrollbars>
                      </Stack>
                    </Stack>
                  )}
                )}
              </Box>
            }
          </Box>
          <Snackbar
              autoHideDuration={3000}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={snackBarOpen}
              onClose={handleSnackClose}
          >
            <Alert
              severity={snackBarFlag === 'error' ? 'error' : 'success'}
              sx={{ width: '100%' }}
              variant="filled"
            >{snackBarMessage}</Alert>
          </Snackbar>
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default PrintComparison
